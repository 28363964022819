import React from 'react';

const SummerCampPage = () => {
  return (
    <a href="https://student-octorun.web.app/register">
      <div className="summerCampContainer">
        <div className="summerCamp">
          <img
            src="/kid stuff newsletter.png"
            width="100%"
            alt="summerCamp2021"
          ></img>
        </div>
      </div>
    </a>
  );
};
export default SummerCampPage;
